import axios, {Axios} from "axios";


const appendDataToGoogleSheets = async (data) => {
    try {

        const response = await axios({
            method: "post",
            url: `https://api.creativefinancecourses.com/helloworld`,

            data: data,
            headers: {
                "Content-Type": "application/json",
            },
        });

        // console.log(response);
        return response;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export { appendDataToGoogleSheets };
