import ReactGA from 'react-ga4';

const track_form = (label) => {
    ReactGA.event({
        action: 'RegisterWebinar',
        category: 'seller_finances',
        label: label
    })
}


export default track_form