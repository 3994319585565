import React from 'react'

import PropTypes from 'prop-types'
import {useState} from "react";
import { useHistory } from "react-router-dom";
import { appendDataToGoogleSheets } from './appendDataToGoogleSheets'
import track_form from './track_form'


import './registration-form.css'

const RegistrationForm = (props) => {

  // const [LNAME, setLName] = useState("");
  // const [FNAME, setName] = useState("");
  // const [EMAIL, setEmail] = useState("");
  // const [PHONE, setPhone] = useState("");

  const [data, setData] = useState({})
  const [buttontext, setText] = useState(props.Confirm_Registration);

  const history = useHistory();

  const updateData = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const data = { FNAME, EMAIL, PHONE, LNAME };
    console.log(data);
    setText("Please wait ..");
    try {
      const success = await appendDataToGoogleSheets(data);
      if (success) {
        setText("Registration Successful ...");
        setTimeout(() => {
          history.push("/thank-you-for-your-registration");

        }, 500);

        if (typeof window.fbq !== 'undefined') {
          window.fbq('trackCustom', 'RegisterWebinar', {promotion: 'seller_finances'});
        }

        track_form("Success");

      } else {
        setText("There was a problem with your registration");
        track_form("Error");
      }
    } catch (e) {
      console.log(e);
      setText("There was a problem with your registration");
      track_form("Error on Catch");
    }
  };

  return (
    <div className="registration-form-container">
      <form
        id="register-mc-form"
        target="_blank"
        className="registration-form-form"
        onSubmit={handleSubmit}
      >
        <div id="signup-staff" className="registration-form-container1">
          <h1 className="registration-form-text Card-Heading teal">
            {props.heading}
          </h1>
          <h3 className="registration-form-text1 Card-Heading teal">
            {props.heading1}
          </h3>
          <input
            type="email"
            name="EMAIL"
            required
            placeholder={props.textinput_placeholder}
            className="registration-form-textinput input"
            onChange={updateData}
          />
          <input
            type="text"
            name="FNAME"
            required
            placeholder={props.textinput_placeholder1}
            className="registration-form-textinput1 input"
            onChange={updateData}
          />
          <input
            type="text"
            name="LNAME"
            required
            placeholder={props.textinput_placeholder2}
            className="registration-form-textinput2 input"
            onChange={updateData}
          />
          <input
            type="tel"
            name="PHONE"
            placeholder={props.textinput_placeholder3}
            className="registration-form-textinput3 input"
            onChange={updateData}
          />
          <button
            name="subscribe"
            type="submit"
            className="registration-form-confirm-registration button"
          >
            {buttontext}
          </button>
        </div>
      </form>
    </div>
  )
}

RegistrationForm.defaultProps = {
  heading: 'Apply for a training.',
  heading1: "Don't miss out the hidden gems of finances and real estate.",
  textinput_placeholder: 'your email',
  textinput_placeholder1: 'your name',
  textinput_placeholder2: 'your lastname',
  textinput_placeholder3: 'your phone',
  Confirm_Registration: 'Register for training',
}

RegistrationForm.propTypes = {
  heading: PropTypes.string,
  heading1: PropTypes.string,
  textinput_placeholder: PropTypes.string,
  textinput_placeholder1: PropTypes.string,
  textinput_placeholder2: PropTypes.string,
  textinput_placeholder3: PropTypes.string,
  Confirm_Registration: PropTypes.string,
}

export default RegistrationForm
