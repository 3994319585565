import React from 'react'

import PropTypes from 'prop-types'

import './blog-post-card2.css'

const BlogPostCard2 = (props) => {
  return (
    <div className={`blog-post-card2-blog-post-card ${props.rootClassName} `}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        image_src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&amp;ixlib=rb-1.2.1&amp;h=1000"
        className="blog-post-card2-image"
      />
      <div className="blog-post-card2-container">
        <div className="blog-post-card2-container1">
          <span className="blog-post-card2-text">{props.label}</span>
          <span className="blog-post-card2-text1">{props.when}</span>
        </div>
        <h1 className="blog-post-card2-text2 Card-Heading teal">
          {props.title}
        </h1>
        <span className="blog-post-card2-text3">{props.description}</span>
        <div className="blog-post-card2-container2">
          <div className="blog-post-card2-profile"></div>
        </div>
      </div>
    </div>
  )
}

BlogPostCard2.defaultProps = {
  title: 'Janis Janovskis',
  author: 'Jane Doe',
  rootClassName: '',
  description:
    'Janis has decades of experience in real estate, technology and finance and has helped countless investors and businesses achieve their goals with seller financing. Through his unique efforts, businesses have raised capital of over 100 million GBP. He will share His knowledge, tips, and tricks with you in this free webinar.',
  profile_alt: 'profile',
  profile_src:
    'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fHBvcnRyYWl0fGVufDB8fHx8MTYyNjM3ODk3Mg&ixlib=rb-1.2.1&h=1200',
  image_alt: 'image',
  image_src: '/j1%20%5B2%5D-1500w.png',
  when: '',
  label: 'Webinar Host',
}

BlogPostCard2.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  rootClassName: PropTypes.string,
  description: PropTypes.string,
  profile_alt: PropTypes.string,
  profile_src: PropTypes.string,
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
  when: PropTypes.string,
  label: PropTypes.string,
}

export default BlogPostCard2
